import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get } from "../../helpers/api_helper";

const Profile = (props) => {
  const [infProfile, setInfProfile] = useState({});
  const profileid = props.match.params.id;
  const viewProfile = async () => {
    const profileDetails = await get(
      "https://api.hsauthentication.com/profiles/get/" + profileid
    );
    setInfProfile(profileDetails.content[0]);
  };
  useEffect(() => {
    // viewProfile();
  }, []);
  return (
    <React.Fragment>
      <Form>
        <div className="page-content">
          <Breadcrumbs
            title={props.t("Profile")}
            breadcrumbItem={props.t("Edit Profile")}
          />
          <Row>
            <Card>
              <CardBody>
                <FormGroup className="d-flex">
                  <Col lg={1}>
                    <img
                      src="/static/media/avatar-2.feb0f89d.jpg"
                      className="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={10} className="text-muted">
                    <h5>{infProfile.firstname + " " + infProfile.lastname}</h5>
                    <p className="mb-1">{infProfile.email}</p>
                    <p className="mb-0">{infProfile.id}</p>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <FormGroup>
                  <Label htmlFor="nameId">Name</Label>
                  <Col lg={12}>
                    <Input
                      type="text"
                      id="nameId"
                      placeholder="Enter your name"
                    ></Input>
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Label for="idEmail" className="mt-3">
                    Email
                  </Label>
                  <Col lg={12}>
                    <Input
                      id="idEmail"
                      name="email"
                      placeholder="email@example.com"
                      type="email"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label for="profileImage" className="mt-3">
                    Profile Photo
                  </Label>
                  <Col lg={12}>
                    <Input id="profileImage" name="file" type="file" />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    lg={{
                      size: 5,
                    }}
                    className="mt-4"
                  >
                    <Button type="submit" className="btn btn-danger">
                      Edit Profile
                    </Button>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Form>
    </React.Fragment>
  );
};
export default withTranslation()(Profile);
Profile.propTypes = {
  t: PropTypes.any,
};
