import React from "react";
import { Redirect } from "react-router-dom";

// Pages
import Dashboard from "../pages/Dashboard/index";
import Clients from "../pages/Clients/index";
import Tickets from "../pages/Tickets/index";
import Items from "../pages/Items/index";
import Shipping from "../pages/Shipping/index";
import Viewclients from "../pages/Clients/View";

import Transactions from "../pages/Transactions/index";
import Users from "../pages/Users/index";
import OrdersView from "../pages/Orders/view/index";
import Orders from "../pages/Orders/index";
import OrdersNew from "../pages/Orders/new/index";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ShowTicket from "../pages/Tickets/ShowTicket/index";
import Profile from "../pages/Profile/profile";
import Authenticationpage from "../pages/Authenticationpage/index";
import Newitem from "../pages/Items/newitem";
import EditItem from "../pages/Items/edititem";
import Newuser from "../pages/Users/new";
import Edituser from "../pages/Users/edit";
import NewClient from "../pages/Clients/new";
import Changepassuser from "../pages/Users/changepassuser";
import Itemdetails from "../pages/Itemdetails/Itemdetails";

import Changepass from "../pages/Changepassword/index";
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/clients", component: Clients },
  { path: "/tickets", component: Tickets },
  { path: "/items", component: Items },
  { path: "/authentication", component: Authenticationpage },

  { path: "/items/new", component: Newitem },
  { path: "/items/details/:id", component: Itemdetails },
  { path: "/items/edit/:id", component: EditItem },
  { path: "/editprofile", component: Profile },
  { path: "/shipping", component: Shipping },
  { path: "/clients/new", component: NewClient },
  { path: "/clients/view/:id", component: Viewclients },

  { path: "/transactions", component: Transactions },
  { path: "/users", component: Users },
  { path: "/users/edit/:userId", component: Edituser },
  { path: "/users/new", component: Newuser },
  { path: "/users/changepassuser/:userId", component: Changepassuser },

  { path: "/changepass", component: Changepass },
  { path: "/orders/view/:id", component: OrdersView },
  { path: "/orders", component: Orders },
  { path: "/orders/new", component: OrdersNew },
  { path: "/orders/auth/:orderid ", component: Orders },
  { path: "/itemdetails/:id", component: Itemdetails },

  // { path: "/orders/authentication", component: Authentication },
  // { path: "/orders/grading", component: Grading },
  { path: "/tickets/showticket/:id", component: ShowTicket },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

]

export { userRoutes, authRoutes }
