import React, { useEffect, useState } from "react";
import { Row, Col, Input, Alert } from "reactstrap";
import { post, postFile } from "../../../helpers/api_helper";
import Switch from "react-switch";
import Select from 'react-select'
import Dropzone from '../../Dropzone';
import DBSelect from "./dbselect";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Formgenerator = (props) => {
  const [valueInput, setValueInput] = useState({});
  const [formErrors, setFormErrors] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [checkhed, setChecked] = useState(false);

  const changeTheFile = (name,files) => {
    setValueInput((oldValues) => {
      let newValues = { ...oldValues };
      newValues[name] = files;
      return newValues;
    });
  }

  useEffect(()=>{
    setValueInput(props.values ?? {});
  },[props.values]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const changevalue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValueInput((oldValues) => {
      let newValues = { ...oldValues };
      newValues[name] = value;
      return newValues;
    });
  };

  const handleSelectChange = (name, value) => {
    props.onChange();
    setValueInput((oldValues) => {
      let newValues = { ...oldValues };
      newValues[name] = value;
      return newValues;
    });
  }

  const beforeSubmit = (values) => {
    const checkFields = props.fields.filter(item=>item?.required === true && (values[item.name] === undefined || values[item.name] === ''));
    if(checkFields.length > 0){
      return [false, 'Please check form and fill required field !'];
    }
    if (props.beforeSubmit) return props.beforeSubmit(values);
      return [true,''];
  };

  const getFormValues = async() => {
    let formData = new FormData();
    for await (let field of props.fields) {
      if(field.type === 'dropzone'){
        if(valueInput[field.name]?.length > 0)
          valueInput[field.name].map((val) => {
            formData.append(field.name+'[]', val)
          })
        else
          formData.append(field.name, []);
      }
      else if(field.type === 'dropdown'){
        formData.append(field.name, valueInput[field.name]?.value);
      }
      else
        formData.append(field.name, valueInput[field.name]);
    }   
    return formData;
  }

  const submitForm = async(e) => {
    e.preventDefault();
    setFormErrors("");
    const [beforeSubmitResult, beforSubmitErrMsg] = beforeSubmit(valueInput);
    if (beforeSubmitResult) {
      const type = props.type ?? "get";
      const url = props.action ?? "";
      if (type === "get") {
      } else if (type === "post") {
        setIsSending(true);
        const values = await getFormValues();
        const isFormMultipart = props.fields.filter(field=> field.type === 'dropzone').length > 0;
        const result = isFormMultipart ? await postFile(url, values) : post(url, values);
        if (props.afterSubmit) {
          const [afterSubmitResult, afterSubmitErrMsg] = props.afterSubmit(result);
          if (!afterSubmitResult) setFormErrors(afterSubmitErrMsg);
        }
        setIsSending(false);
      }
    } else {
      setFormErrors(beforSubmitErrMsg);
    }
  };

  const fieldgenerator = (field) => {
    switch (field.type) {
      case "text":
        return (
          <Col lg={12}>
            <Input
              type="text"
              id={field.name}
              readOnly={field.readOnly ?? false}
              disabled={field.disabled ?? false}
              name={field.name}
              value={valueInput[field?.name] ?? valueInput[field?.valueEdit] ?? ''}
              onChange={changevalue}
            />
          </Col>
        );
      case "number":
        return (
          <Col lg={12}>
            <Input
              type="number"
              id={field.name}
              readOnly={field.readOnly ?? false}
              name={field.name}
              min={field.min}
              disabled={field.checkIsDisabled ? field.checkIsDisabled(valueInput) : false}
              max={field.max}
              value={valueInput[field?.name] ?? valueInput[field?.valueEdit] ?? 0}
              onChange={changevalue}
            />
          </Col>
        );
        case "date":
          return (
            <Col lg={12}>
               <DatePicker
                  selected={valueInput[field?.name] ?? valueInput[field?.valueEdit] ?? '' }
                  onChange={(date) => handleSelectChange(field.name, date)}
                  className="form-control"
                  {...field.attributes(valueInput)}
                />
            </Col>
          );
      case "textarea":
        return (
          <Col lg={12}>
            <textarea
              type="text"
              rows={6}
              id={field.name}
              className="form-control"
              readOnly={field.readOnly ?? false}
              name={field.name}
              disabled={field.disabled ?? false}
              onChange={changevalue}
              defaultValue={valueInput[field?.name] ?? valueInput[field?.valueEdit] ?? ''}
            ></textarea>
          </Col>
        );
      case 'dropzone': 
          return (
            <Col lg={12}>
              <Dropzone name={field.name} onChange={changeTheFile} />
            </Col>
          );
      case "password":
        return (
          <Col lg={12}>
            <Input
              type="password"
              readOnly={field.readOnly ?? false}
              name={field.name}
              disabled={field.disabled ?? false}
              onChange={changevalue}
            />
          </Col>
        );
      case "file":
        return (
          <>
            <Col lg={12}>
              <Input
                type="file"
                readOnly={field.readOnly ?? false}
                name={field.name}
                disabled={field.disabled ?? false}
                onChange={changevalue}
              />
            </Col>
          </>
        );
      case 'dropdown':
        const fieldValue = field.options?.filter(itm => (itm.value === field.value) || (itm.value === valueInput[field.name]))[0];
        // console.log(fieldValue);
        return (
          <Col lg={12}>
            <Select 
              id={field.name}
              defaultValue={field.options[0]}
              onChange={val=>handleSelectChange(field.name, val)} 
              options={field.options} />
          </Col>
        );
      case 'dbdropdown':
          return (
            <Col lg={12}>
              <DBSelect onChange={val=>handleSelectChange(field.name, val)} url={field.url} map={field.map} />
            </Col>
          );
      case "switch":
        return (
          <>
            <Col lg={12}>
              <label>
                <Switch
                  onChange={handleChange}
                  checked={checkhed}
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 14,
                        color: "orange",
                        paddingRight: 2,
                      }}
                    >
                      No
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 14,
                        color: "#fff",
                        paddingRight: 2,
                      }}
                    >
                      Yes
                    </div>
                  }
                  onColor="#52e32e"
                  offColor="#d3d3d3"
                />
              </label>
            </Col>
          </>
        );
      default:
        break;
    }
  };
  
  return (
    <form onSubmit={submitForm}>
      <Row>
        {formErrors ? (
          <Col lg={12}>
            <Alert color="warning">{formErrors}</Alert>
          </Col>
        ) : (
          ""
        )}
        {props.fields.map((field, index) => {
          return (
            <Col lg={field.colclass ?? 12} key={index}>
              <label htmlFor={field.name} className="mt-3">{field.label}  {( field.required ? <span className="text-danger">*</span> : '' )}</label>
              {fieldgenerator(field)}
            </Col>
          );
        })}

        <Col lg={6}>
          <input
            className="mt-3 btn btn-success"
            disabled={isSending && false}
            type="submit"
            value={isSending && false ? "Sending Form..." : "Submit"}
          />
        </Col>
      </Row>
    </form>
  );
};

Formgenerator.defaultProps = {
  onChange: () => {}
}

Formgenerator.protoTypes = {
  onChange: PropTypes.func
}

export default Formgenerator;
