import React from "react"
import { Button,Input,Row, Col, Card, CardBody } from "reactstrap"

//Import Breadcrumb
const ReplyTicket = () => {
  return (
    <React.Fragment>
            <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
              <form>
              <Input
                  name="reply_text"
                  type="textarea"
                  rows="10"
                />
              </form>
            <Row className="mt-3">
              <Col md="6">
              <Button type="button" color="primary">
                Send <i className="fab fa-telegram-plane ms-1"></i>
              </Button>
              </Col>
              <Col md="6">
              <div className="mt-4 mt-md-0 w-50" style={{float: "right"}}>
                {/* <Input
                  type="file"
                  className="form-control"
                /> */}
              </div>
              </Col>
            </Row>
            </CardBody>
            </Card>
          </Col>
        </Row>
    </React.Fragment>
  )
}

export default ReplyTicket
