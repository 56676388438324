import Select from "react-select";
import Dropzone from "../../components/Common/Dropzone/dropzone";
import Switch from "react-switch";
import "./categorystyle.sass";
import { useState } from "react";
import React from "react";
import { Row, Col, Label, Input, Button } from "reactstrap";
import Signatures from "./signatures";
const label = { inputProps: { "aria-label": "Switch demo" } };

function Category() {
  const [checkhed, setChecked] = useState(false);
  // const [checkhed1, setChecked1] = useState(false);
  // const [checkhed2, setChecked2] = useState(false);
  // const [checkhed3, setChecked3] = useState(false);

  const handleChange = (nextChecked) => {
    console.log(nextChecked);
    setChecked(nextChecked);
    handelCategoryChange("question_1", nextChecked);
  };
  // const handleChange1 = (nextChecked) => {
  //   setChecked1(nextChecked);
  // };
  // const handleChange2 = (nextChecked) => {
  //   setChecked2(nextChecked);
  // };
  // const handleChange3 = (nextChecked) => {
  //   setChecked3(nextChecked);
  // };
  const [value, setValue] = React.useState(50);

  const [formDetails, setformDetails] = useState({
    title: "",
    category_select: null,
    sub_category: null,
    medium: "",
    nft_url: "",
    artist: "",
    year_date: "",
    subject: "",
    size: "",
    edition_type: "",
    edition_number: "",
    performer: "",
    venue_type: null,
    promoter: "",
    series_type: "",
    question_1: 0,
    question_2: 0,
    question_3: 0,
    question_4: 0,
    question_5: 0,
    question_6: 0,

    signatures: "",
    inspector: " ",
    Select_image: " ",
    certificate: " ",
  });
  const handelCategoryChange = (name, value) => {
    setformDetails((oldFormDetails) => {
      let newFormDetails = { ...oldFormDetails };
      newFormDetails[name] = value;
      console.log(newFormDetails);
      return newFormDetails;
    });
  };
  const editionTypes = [
    { value: "open", label: "Open" },
    { value: "limited", label: "Limited" },
    { value: "one of a Kind", label: "One of a Kind" },
  ];
  const categories = [
    { value: "music ", label: "Music" },
    { value: "entertainment ", label: "Entertainment/Actor  " },
    { value: "historical ", label: "Historical" },
    { value: "sports ", label: "Sports/Racing" },
  ];
  const subcategories = [
    { value: "poster", label: "Poster" },
    { value: "ticket", label: "Ticket" },
    { value: "photo", label: "Photo" },
  ];
  const artits = [
    { value: "artist", label: "artist" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];
  const [signaturesInput, setSignaturesInput] = useState(0);

  const [notes, setNotes] = useState([
    {
      note: "",
      public: false,
    },
  ]);
  const addNewNote = () => {
    setNotes((notes) => {
      let newText = [...notes];
      newText.push({
        note: "",
        public: false,
      });
      return newText;
    });
  };
  const inputChange = (index, value) => {
    let a = [...notes];
    a[index].note = value;
    setNotes(a);
  };
  const removeText = (index) => {
    let newText = [...notes];
    newText.splice(index, 1);
    setNotes(newText);
  };
  const changeSignatures = (name, value, id) => {
    console.log(name, value, id);
    // setformDetails((oldFormDetails) => {
    // let newItems = JSON.parse(JSON.stringify(oldFormDetails));
    // console.log(oldFormDetails);
    // const index = newItems.findIndex((obj) => obj.index == id);
    // if (name == "q-4") {
    //   newItems.signatures["q-4"] = value;
    //   console.log(newItems);
    // }
    // return newItems;
    // });
  };
  return (
    <>
      <div className="card-body">
        <Row>
          <Col lg={12}>
            <Label htmlFor="titleId" className="color-size">
              Title
            </Label>
            <Input
              type="text"
              id="titleId"
              className="style-input"
              value={formDetails.title}
              onChange={(e) => handelCategoryChange("title", e.target.value)}
            />
          </Col>
          <Col lg={12} className="mt-3">
            <Row>
              <Col lg={4}>
                <Label htmlFor="categoryselect" className="color-size">
                  Category
                </Label>
                <Select
                  options={categories}
                  id="categoryselect"
                  value={formDetails.category_select}
                  onChange={(selectedOption) =>
                    handelCategoryChange("category_select", selectedOption)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="subCategoryselect" className="color-size">
                  Sub Category
                </Label>
                <Select
                  options={subcategories}
                  value={formDetails.sub_category}
                  id="subCategoryselect"
                  onChange={(selectedOption) =>
                    handelCategoryChange("sub_category", selectedOption)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="mediumId" className="color-size">
                  Medium
                </Label>
                <Input
                  type="text"
                  id="mediumId"
                  className="style-input"
                  value={formDetails.medium}
                  onChange={(e) =>
                    handelCategoryChange("medium", e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="mt-3">
            <Row>
              <Col lg={4}>
                <Label htmlFor="nfturlId" className="color-size">
                  NFT URL
                </Label>
                <Input
                  id="nfturlId"
                  className="style-input"
                  value={formDetails.nft_url}
                  onChange={(e) =>
                    handelCategoryChange("nft_url", e.target.value)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="artistId" className="color-size">
                  Artist/Main Autograph
                </Label>
                <Input
                  type="text"
                  id="artistId"
                  className="style-input"
                  value={formDetails.artist}
                  onChange={(e) =>
                    handelCategoryChange("artist", e.target.value)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="venueSelect" className="color-size">
                  Venue
                </Label>
                <Select
                  options={editionTypes}
                  default="open"
                  id="venueSelect"
                  value={formDetails.venue_type}
                  onChange={(selectedOption) =>
                    handelCategoryChange("venue_type", selectedOption)
                  }
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className="mt-3">
            <Row>
              <Col lg={4}>
                <Label htmlFor="dateId" className="color-size">
                  Date/ Year
                </Label>
                <Input
                  type="text"
                  id="dateId"
                  className="style-input"
                  value={formDetails.year_date}
                  onChange={(e) =>
                    handelCategoryChange("year_date", e.target.value)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="subjectId" className="color-size">
                  Subject
                </Label>
                <Input
                  id="subjectId"
                  className="style-input"
                  value={formDetails.subject}
                  onChange={(e) =>
                    handelCategoryChange("subject", e.target.value)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="sizeId" className="color-size">
                  Size
                </Label>
                <Input
                  type="text"
                  id="sizeId"
                  className="style-input"
                  value={formDetails.size}
                  onChange={(e) => handelCategoryChange("size", e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="mt-3">
            <Row>
              <Col lg={4}>
                <Label htmlFor="editiontypeselect" className="color-size">
                  Edition Type
                </Label>
                <Select
                  options={editionTypes}
                  default="open"
                  id="editiontypeselect"
                  value={formDetails.edition_type}
                  onChange={(selectedOption) =>
                    handelCategoryChange("edition_type", selectedOption)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="numberId" className="color-size">
                  Edition Number
                </Label>
                <Input
                  id="numberId"
                  className="style-input"
                  value={formDetails.edition_number}
                  onChange={(e) =>
                    handelCategoryChange("edition_number", e.target.value)
                  }
                />
              </Col>
              <Col lg={4}>
                <Label htmlFor="performerId" className="color-size">
                  Performer
                </Label>
                <Input
                  type="text"
                  id="performerId"
                  className="style-input"
                  value={formDetails.performer}
                  onChange={(e) =>
                    handelCategoryChange("performer", e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className="mt-3">
            <Row>
              <Col lg={6}>
                <Label htmlFor="promoterId" className="color-size">
                  Promoter
                </Label>
                <Input
                  type="text"
                  id="promoterId"
                  className="style-input"
                  value={formDetails.promoter}
                  onChange={(e) =>
                    handelCategoryChange("promoter", e.target.value)
                  }
                />
              </Col>
              <Col lg={6}>
                <Label htmlFor="seriesSelect" className="color-size">
                  Series
                </Label>
                <Select
                  options={editionTypes}
                  default="open"
                  id="seriesSelect"
                  value={formDetails.series_type}
                  onChange={(selectedOption) =>
                    handelCategoryChange("series_type", selectedOption)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <h6 className="h6-first">1) Is Items Feasibility Ok?</h6>
              </Col>
              <Col lg={6} className="response-ques">
                <Label>
                  <Switch
                    onChange={handleChange}
                    // onChangeCapture={(e) =>
                    //   handelCategoryChange("question_1", e.target.value)
                    // }
                    // isOn={value}
                    // handleToggle={() => handleChange(!value)}
                    checked={checkhed}
                    uncheckedIcon={<div className="switch-no">No</div>}
                    checkedIcon={<div className="switch-yes">Yes</div>}
                    onColor="#52e32e"
                    offColor="#d3d3d3"
                    value={checkhed}
                  />
                </Label>
              </Col>
            </Row>
          </Col>
          <Label>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <h6 className="h6-first">2) Item Feasibility Score?</h6>
                </Col>
                <Col lg={6} className="response-ques">
                  <Input
                    type="range"
                    className="form-range"
                    min="0"
                    max="100"
                    width="100%"
                    step="1"
                    id="customRange3"
                    value={formDetails.question_2}
                    onChange={(e) =>
                      handelCategoryChange("question_2", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Label>
          <Label>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <h6 className="h6-first">3) True to Comparables?</h6>
                </Col>
                <Col lg={6} className="response-ques">
                  <Input
                    type="range"
                    className="form-range"
                    min="0"
                    max="100"
                    width="100%"
                    step="1"
                    id="customRange3"
                    value={formDetails.question_3}
                    onChange={(e) =>
                      handelCategoryChange("question_3", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Label>
          <Label>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <h6 className="h6-first">4) Official Release?</h6>
                </Col>
                <Col lg={6} className="response-ques">
                  <Input
                    type="range"
                    className="form-range"
                    min="0"
                    max="100"
                    width="100%"
                    step="0.5"
                    id="customRange3"
                    value={formDetails.question_4}
                    onChange={(e) =>
                      handelCategoryChange("question_4", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Label>
          <Label>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <h6 className="h6-first">5) Perovenance or Support?</h6>
                </Col>
                <Col lg={6} className="response-ques">
                  <Input
                    type="range"
                    className="form-range"
                    min="0"
                    max="100"
                    width="100%"
                    step="1"
                    id="customRange3"
                    value={formDetails.question_5}
                    onChange={(e) =>
                      handelCategoryChange("question_5", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Label>
          <Label>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <h6 className="h6-first">6) consult Subject Expert?</h6>
                </Col>
                <Col lg={6} className="response-ques">
                  <Input
                    type="range"
                    className="form-range"
                    min="0"
                    max="100"
                    width="100%"
                    step="1"
                    id="customRange3"
                    value={formDetails.question_6}
                    onChange={(e) =>
                      handelCategoryChange("question_6", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Label>
          <Col lg={3} className="row-style-signatures">
            <Label htmlFor="inputSigId" className="text-title">
              Signatures
            </Label>
            <Input
              type="number"
              min="0"
              className="style-input"
              id="inputSigId"
              value={signaturesInput}
              onChange={(e) => setSignaturesInput(e.target.value)}
            />
          </Col>
          {/* *****************New Map********************* */}

          <div className="accordion" id="accordionExample">
            {Array.from({ length: signaturesInput }).map((item, index) => (
              <>
                <Signatures
                  id={index}
                  key={index}
                  signatures={formDetails.signatures}
                  onChange={changeSignatures}
                />
              </>
            ))}
          </div>

          <Col lg={12} className=" row-style width-style">
            <Col lg={6} className=" margin-right width-style">
              <Label htmlFor="textImgId" className="text-title">
                Inspector's Comment
              </Label>
              <textarea
                className="textarea-style text-file"
                id="textImgId"
                value={formDetails.inspector}
                onChange={(e) =>
                  handelCategoryChange("inspector", e.target.value)
                }
              ></textarea>
            </Col>
            <Col lg={6} className="margin-left mt-2 width-style">
              <Label className="text-title-image">Main Image</Label>
              <Dropzone maxnumber={1} />
            </Col>
          </Col>
          <Col lg={12} className=" row-style">
            <Col lg={12} className=" div-content div-btt-new-note">
              <Button className="btn btn-success" onClick={() => addNewNote()}>
                New Content
              </Button>
            </Col>
          </Col>
          <Col lg={12} className=" border-bottom row-style"></Col>
          {notes.map((item, index) => {
            return (
              <>
                <Col lg={12} key={index}>
                  <Row>
                    <Col lg={8} className=" div-two-btt">
                      <Button
                        className="btn btn-danger"
                        onClick={() => removeText(index)}
                      >
                        Remove Note
                      </Button>
                    </Col>

                    <Col lg={4} className=" switch-div">
                      <p className="color-size-label">Public</p>
                      <Label>
                        <Switch
                          onChange={handleChange}
                          checked={checkhed}
                          uncheckedIcon={<div className="switch-no">No</div>}
                          checkedIcon={<div className="switch-yes">Yes</div>}
                          onColor="#52e32e"
                          offColor="#d3d3d3"
                        />
                      </Label>
                      {/* </Label> */}
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} className=" row-style">
                  <Col lg={6} className=" margin-right">
                    <Label htmlFor="textImgId-2" className="text-title">
                      Inspector's Comment
                    </Label>
                    <textarea
                      onChange={(e) => inputChange(index, e.target.value)}
                      className="textarea-style text-file"
                      id="textImgId-2"
                    ></textarea>
                  </Col>
                  <Col lg={6} className=" margin-left mt-2">
                    <Label className="text-title-image">Select Images</Label>
                    <Dropzone />
                  </Col>
                </Col>
                <Col lg={12} className=" border-bottom row-style"></Col>
              </>
            );
          })}
          <Col lg={12} className=" row-style">
            <Col lg={6}>
              <Label htmlFor="qrId" className="color-size-certificate">
                <span style={{ fontSize: "14px" }}>Certificate Id</span>
                <Input
                  type="text"
                  id="qrId"
                  className="style-input"
                  value={formDetails.certificate}
                  onChange={(e) =>
                    handelCategoryChange("certificate", e.target.value)
                  }
                />
              </Label>
            </Col>
          </Col>
          <Col className="col-md-12 border-bottom row-style"></Col>
          <Col className="col-md-12 div-btt-sub pb-3">
            <Button
              className="btn btn-success"
              // onClick={() => formsubmit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Category;
