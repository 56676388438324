import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { get } from "../../helpers/api_helper";
import Skeleton from "react-loading-skeleton";

const DatatableTables = (props) => {
  const [loading, setLoading] = useState(false);
  const [tablData, settablData] = useState(null);
  const columns = props.columns;

  const loadData = async () => {
    const url = props.link + "/" + (props.page || 1);
    setLoading(true);
    const tableData = await get(url);
    setLoading(false);
    settablData(tableData?.content);
  };
  useEffect(() => {
    loadData();
  }, []);

  const data = {
    columns: columns,
    rows: tablData ? tablData.map((data) => props.rows(data)[0]) : [],
  };
  return (
    <>
      {loading ? (
        <Skeleton count={10} />
      ) : (
        <MDBDataTable responsive striped bordered data={data} />
      )}
    </>
  );
};

export default DatatableTables;
