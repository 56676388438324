import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import DatatableTables from "../../common/Datatable/index";
import Dropdown from "../../common/Dropdown/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const dditems = [
  {
    label: "Item details",
    link: "/items/details/:itemId",
  },
  {
    label: "Edit Item",
    link: '/items/edit/:itemId',
  },
  {
    label: "Authenticate",
    link: '/authentication',
  },
];
const newLocal = '1';
const Items = (props) => {
  const defultShowComponent = props.selfLoad ?? true;
  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Title",
      field: "title",
      sort: "asc",
      width: 270,
    },
    {
      label: "Client",
      field: "client",
      sort: "asc",
      width: 100,
    },
    {
      label: "Date Registered",
      field: "date_registered",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
    {
      label: "Value",
      field: "value",
      sort: "asc",
      width: 150,
    },
    {
      label: "",
      field: "dd",
      width: 100,
    },
  ];

  const getStatus = (status) => {
    switch(status){
      case newLocal:
        return 'approved';
      default:
        return '-';
    }
  }

  const rowHandler = (data) => {
    let dditem = JSON.parse(JSON.stringify(dditems));
    dditem[0].link = dditem[0].link.replace(':itemId', data.id);
    dditem[1].link = dditem[1].link.replace(':itemId', data.id);

    const row = [
      {
        id: data.id,
        title: data.title,
        client: data.firstname+ ' ' + data.lastname,
        date_registered: data.date_registered,
        status: getStatus(data.status),
        value: data.value,
        dd: <Dropdown items={dditem} />,
      },
    ];
    return row;
  };
  return (
    <React.Fragment>
      <div className={defultShowComponent ? "page-content" : ""}>
        {defultShowComponent ? (
          <Breadcrumbs
            title={props.t("Items")}
            breadcrumbItem={props.t("Items")}
          />
        ) : (
          ""
        )}

        <Row>
          <Col lg={12} style={{ textAlign: "right", marginBottom: "30px" }}>
            <Link to="/items/new" className="btn btn-success">
              New Item
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <DatatableTables
                  link={props.url ?? "/items/list"}
                  columns={columns}
                  rows={rowHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Items);
Items.propTypes = {
  t: PropTypes.any,
};
