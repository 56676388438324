import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { get } from '../../helpers/api_helper';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Modal, ModalHeader, ModalFooter, Button ,ModalBody
} from "reactstrap";


import "./DropdownList.scss";

const Dropdown = (props) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(false);
  const modalToggle = () => setModalOpen(!modalOpen);
  const [removeUrl , setRemoveUrl] = useState('');
  const handleRemoveButton = (e, url) => {
    setModalOpen(true);
    setRemoveUrl(url);
  }
  const handleRemove = async(e) => {
    await get(removeUrl);
    setModalOpen(false);
    history.push(props.backurl);
    window.location.reload(false);
  }
  const [drp_secondary_sm, setDrp_secondary_sm] = useState(false);
  return (
    <React.Fragment>
      <div className="btn-group me-1 mt-2 dropdown">
        <ButtonDropdown
          isOpen={drp_secondary_sm}
          toggle={() => setDrp_secondary_sm(!drp_secondary_sm)}
        >
          <DropdownToggle caret>
            {" "}
            <i className="mdi mdi-dots-horizontal" style={{ color: "gray" }} />
          </DropdownToggle>
          <DropdownMenu>
            {props.items.map((item, index) => {
              return (
                item.isRemove ?<DropdownItem key={index} url={item.url} onClick={e=>handleRemoveButton(e, item.url)}>{item.label}</DropdownItem> :
                <Link key={index} to={item.link}>
                  <DropdownItem>{item.label}</DropdownItem>
                </Link>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      <Modal  toggle={modalToggle} isOpen={modalOpen}>
        <ModalHeader> 
          Attention
        </ModalHeader>
        <ModalBody>
          Are you sure you want to remove ?
        </ModalBody>
        <ModalFooter>
          <Button color="danger"
            onClick={handleRemove}
          >
            Remove Now
          </Button>
          {' '}
          <Button onClick={e=> setModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Dropdown;
