import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TicketsPage from "../../Tickets";
import ItemsPage from "../../Items";
import TransactionsPage from "../../Transactions";
import ChangepasswordPage from "../../Changepassword";
import { get, API_URL } from "../../../helpers/api_helper";
import Skeleton from "react-loading-skeleton";
const Viewclients = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [infClients, setInfClients] = useState(null);
  const clientid = props.match.params.id;
  const viewClients = async () => {
    const clientDetails = await get("/clients/get/" + clientid);
    setInfClients(clientDetails.content[0]);
  };

  useEffect(() => {
    viewClients();
  }, []);

    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs
            title={props.t("Clients")}
            breadcrumbItem={props.t("View Clients")}
          />
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={1}>
                    <img alt=""
                      src={API_URL+'/clients/pic/'+infClients?.id}
                      className="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={7} className="text-muted">
                    <h5>{infClients?.firstname + " " + infClients?.lastname}</h5>
                    <p className="mb-1">{infClients?.email}</p>
                    <p className="mb-0">{infClients?.id}</p>
                  </Col>
                  <Col lg={4} style={{ textAlign: "right" }}>
                    {/* <Button type="submit" className="btn btn-success mt-3 ">
                      Login as client
                    </Button> */}
                    <Button
                      type="submit"
                      className="btn btn-danger mt-3 "
                      style={{ marginLeft: "5px" }}
                    >
                      Disable Client
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={(e) => setActiveTab("1")}
                    >Orders</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={(e) => setActiveTab("2")}
                    >Items</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={(e) => setActiveTab("3")}
                    >Transactions</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={(e) => setActiveTab("4")}
                    >
                      Tickets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "5" ? "active" : ""}
                      onClick={(e) => setActiveTab("5")}
                    >
                      Change Password
                    </NavLink>
                  </NavItem>
                </Nav>
                {infClients === null ? 
                <Skeleton count={10} /> : 
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <ItemsPage url={'/items/listbyclient/'+infClients.id} selfLoad={false} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ItemsPage url={'/items/listbyclient/'+infClients.id} selfLoad={false} />
                  </TabPane>
                  <TabPane tabId="3">
                    <TransactionsPage url={'/transactions/listbyclient/'+infClients.id} selfLoad={false} />
                  </TabPane>
                  <TabPane tabId="4">
                    <TicketsPage url={'/tickets/listbyclient/'+infClients.id} selfLoad={false} />
                  </TabPane>
                  <TabPane tabId="5">
                    <ChangepasswordPage selfLoad={false} />
                  </TabPane>
                </TabContent> }
              </CardBody>
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
};
export default withTranslation()(Viewclients);
Viewclients.propTypes = {
  t: PropTypes.any,
};
