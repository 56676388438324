import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)


export {
  postJwtLogin
}
