import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const View = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Shipping")}
          breadcrumbItem={props.t("Shipping")}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={1}>
                    <img
                      src="/static/media/avatar-2.feb0f89d.jpg"
                      class="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={11} className="text-muted">
                    <h5>admin</h5>
                    <p class="mb-1">admin@themesbrand.com</p>
                    <p class="mb-0">Id no:</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={9}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={2}>
                    <img
                      src="https://www.fanaticsauctions.com/iSynApp/auctionDisplay.action?auctionId=4040167&pgcust2=nba"
                      class="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={4}>
                    <p>
                      <strong>
                        Jasun Tatum & Larry Bird Boston Client Autographed
                        Spalding Indoor/Outdoor Basketball <br />
                        $2900
                      </strong>
                    </p>
                  </Col>
                  <Col lg={2} style={{ padding: "0" }}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Authentication? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-success badge badge-secondary">
                        Yes
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Grading? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-danger badge badge-secondary">
                        No
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <button className="btn btn-danger">Delete</button>
                  </Col>
                  <Col lg={2}>
                    <img
                      src="https://www.fanaticsauctions.com/iSynApp/auctionDisplay.action?auctionId=4040167&pgcust2=nba"
                      class="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={4}>
                    <p>
                      <strong>
                        Aaron Judge New York Yankeeds Autogher <br />
                        $2900
                      </strong>
                    </p>
                  </Col>
                  <Col lg={2} style={{ padding: "0" }}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Authentication? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-success badge badge-secondary">
                        Yes
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Grading? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-danger badge badge-secondary">
                        No
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <button className="btn btn-danger">Delete</button>
                  </Col>
                  <Col lg={2}>
                    <img
                      src="https://www.fanaticsauctions.com/iSynApp/auctionDisplay.action?auctionId=4040167&pgcust2=nba"
                      class="avatar-md mx-auto rounded-circle"
                    />
                  </Col>
                  <Col lg={4}>
                    <p>
                      <strong>
                        Jasun Tatum & Larry Bird Boston Client Autographed
                        Spalding Indoor/Outdoor Basketball <br />
                        $2900
                      </strong>
                    </p>
                  </Col>
                  <Col lg={2} style={{ padding: "0" }}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Authentication? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-success badge badge-secondary">
                        Yes
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <Col lg={12}>
                      <sapan>
                        <strong>Grading? </strong>
                      </sapan>
                    </Col>
                    <Col lg={12}>
                      <span class="rounded-pill bg-danger badge badge-secondary">
                        No
                      </span>
                    </Col>
                  </Col>
                  <Col lg={2}>
                    <button className="btn btn-danger">Delete</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <CardBody>
                <Row>Change Status</Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>Payments</Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={9}>
            <Card>
              <CardBody>Order history</CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(View);

View.propTypes = {
  t: PropTypes.any,
};
