import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import DatatableTables from "../../common/Datatable/index"
import Dropdown from "../../common/Dropdown/index"
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
const items =[
  {
    label: "Show Shipping",
    link: "/",
  },
  {
    label: "Close Shipping",
    link: "/",
  },
]
const Shipping = (props) => {
  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Tracking Code",
      field: "trackingNo",
      sort: "asc",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 200,
    },
    {
      label: "Item Title",
      field: "title",
      sort: "asc",
      width: 100,
    },
    {
      label: "Company",
      field: "company",
      sort: "asc",
      width: 150,
    },
    {
      label: "",
      field: "dd",
      sort: "disabled",
      width: 100,
    },
  ]
  const rowHandler = (data) => {
      const rows = [
        {
          id: data.shipId,
          trackingNo: data.tracking_code,
          status: data.status,
          title: data.item_title,
          company: data.ship_company_title,
          dd: <Dropdown items={items}/>
      
        }
      ]
      return rows
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={props.t("Shipping")} breadcrumbItem={props.t("Shipping")} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                
                  <DatatableTables link={"/shippings/list"} columns={columns} rows={rowHandler}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(Shipping)
Shipping.propTypes = {
  t: PropTypes.any
}