import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Formgenerator from "../../components/Common/Formgenerator/formgenerator";
import { useHistory } from "react-router-dom";
import { get } from "../../helpers/api_helper";
import fields from "./fields";

const EditItem = (props) => {
  const history = useHistory();
  const [itemInfo, setItemInfo] = useState(null);

  const itemId = props.match.params.id ?? -1;
  const getItemInfo = useCallback(async(itemId) => {
    const itemInfo = await get('/items/get/' + itemId);
    return itemInfo;
  },[]);


  useEffect(()=>{
    (async function(){
      const itemInfo = await getItemInfo(itemId);
      setItemInfo(itemInfo.content[0]);
    })();
    
  }, []);

  const checkResult = (result) => {
    if (result?.content === "ok"){
      history.push("/items");
      return [true,''];
    }
    else {
      return [false, result?.content];
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Items")}
          breadcrumbItem={props.t("New Items")}
        />
        <Row>
          <Col lg={12} style={{ textAlign: "right" }} className="mb-3">
            <Link to="/items" className="btn btn-success">
              Back
            </Link>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Formgenerator
                  fields={fields}
                  values={itemInfo}
                  disabled={itemInfo === null}
                  type="post"
                  action="/items/add"
                  afterSubmit={checkResult}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(EditItem);
EditItem.propTypes = {
  t: PropTypes.any,
};
