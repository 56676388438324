import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Formgenerator from "../../components/Common/Formgenerator/formgenerator";
import { useHistory } from "react-router-dom";

const NewClient = (props) => {
  const history = useHistory();
  const fields = [
    {
      label: "First Name",
      type: "text",
      value: "",
      name: "fname",
      colclass: 6,
    },
    {
      label: "Last Name",
      type: "text",
      value: "",
      name: "lname",
      colclass: 6,
    },
    {
      label: "Email",
      type: "text",
      value: "",
      name: "email",
      colclass: 6,
    },
    {
        label: "Company Name",
        type: "text",
        value: "",
        name: "repeat",
        colclass: 6,
    },
    {
      label: "Password",
      type: "password",
      value: "",
      name: "password",
      colclass: 6,
    },
    {
      label: "Repeat Password",
      type: "password",
      value: "",
      name: "repeat",
      colclass: 6,
    },
    {
        label: "Dealer/Wholesale",
        type: "switch",
        value: 2,
        name: "type",
    },
  ];

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkForm = (values) => {
    const name = values?.name || "";
    const email = values?.email || "";
    const password = values?.password || "";
    const repeatPassword = values?.repeat || "";
    const title = values?.title || "";
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      repeatPassword === "" ||
      title === ""
    ) {
      return [false, "Input form values are empty !"];
    } else if (!validateEmail(email)) {
      return [false, "Email is invalid !"];
    } else if (password !== repeatPassword) {
      return [false, "Password not match"];
    } else if (password.length < 8) {
      return [false, "Password Length Less than 8 characters"];
    }
    return [true, ""];
  };

  const checkResult = (result) => {
    if (result?.content === "ok"){
      history.push("/clients");
      return [true, ''];
    }
    else {
      console.log(result);
      return [false, result?.content];
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Clients")}
          breadcrumbItem={props.t("New Client")}
        />
        <Row>
          <Col lg={12} style={{ textAlign: "right" }} className="mb-3">
            <Link to="/clients" className="btn btn-success">
              Back
            </Link>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Formgenerator
                  fields={fields}
                  type="post"
                  action="/clients/new"
                  beforeSubmit={checkForm}
                  afterSubmit={checkResult}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(NewClient);
NewClient.propTypes = {
  t: PropTypes.any,
};
