import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {useState} from 'react'
import {  Row, Col, Card, CardBody } from "reactstrap"
import ReplyTicket from "../ReplyTicket/index"
import { withTranslation } from "react-i18next"
import {get} from '../../../helpers/api_helper';
import PropTypes from 'prop-types'

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import avatar2 from "../../../assets/images/users/avatar-2.jpg"
import img3 from "../../../assets/images/small/img-3.jpg"
import img4 from "../../../assets/images/small/img-4.jpg"

const ShowTicket = (props) => {
  const [ticketInfo , setTicketinfo] = useState();
  const loadData = async(ticketId) => {
    const ticketData = await get('/tickets/view/'+ticketId);
    setTicketinfo(ticketData.content);
    console.log(ticketInfo);
  }
  useEffect(()=>{
    const ticketId = props.match.params.id;
    loadData(ticketId);
  },[])
  return (
    <React.Fragment>
      <div className="page-content">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Ticket")} breadcrumbItem={props.t("Show ticket")} />
          <Row>
            <div className="d-flex mb-4">
              <div className="flex-1">
                <h2 className="font-size-24 mt-1">{ticketInfo === undefined ? 'nonono' : ticketInfo.info.title }</h2>
                <small className="text-muted">{ticketInfo === undefined ? 'nonono' : ticketInfo.info.date_registered + " | " + ticketInfo.info.department_name + " | " + ticketInfo.info.firstname + " " + ticketInfo.info.lastname }</small>
              </div>
            </div>
            <Col xs="12">
              <ReplyTicket />
              <div className="mb-3">
                
                  {(ticketInfo !== undefined) ? ticketInfo.replies.map((reply) => {
                    console.log(reply);
                  return (<Card key={reply.id}><CardBody>
                    <div className="d-flex mb-4">
                      <img
                        className="d-flex me-3 rounded-circle avatar-sm"
                        src="/static/media/avatar-2.feb0f89d.jpg"
                        alt={reply.firstname + ' ' + reply.lastname}
                      />
                      <div className="flex-1">
                        <h5 className="font-size-14 mt-1">
                          {reply.name}
                        </h5>
                        <small className="text-muted">{reply.user_email}</small>
                      </div>
                    </div>

                    {reply.content}
                    
                    {reply.attachments ? 
                    <>
                    <hr />
                    <Row>
                      <Col xl="2" xs="6">
                        <Card>
                          <img
                            className="card-img-top img-fluid"
                            src={img3}
                            alt="Qovex"
                          />
                          <div className="py-2 text-center">
                            <Link to="" className="fw-medium">
                              Download
                            </Link>
                          </div>
                        </Card>
                      </Col>
                    </Row></> : '' }
                  </CardBody></Card>)
                  }) : ''}
                
              </div>
            </Col>
          </Row>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ShowTicket)

ShowTicket.propTypes = {
  t: PropTypes.any
}
