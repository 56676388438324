import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Formgenerator from "../../components/Common/Formgenerator/formgenerator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Changepassuser = (props) => {
  const history = useHistory();
  const userId = props.match.params.userId ?? -1;
  const fields = [
    {
      label: "Current Password",
      type: "password",
      value: "",
      name: "oldpass",
    },
    {
      label: "New Password",
      type: "password",
      value: "",
      name: "newpass",
    },
    {
      label: "Repeat New Password",
      type: "password",
      value: "",
      name: "repeatNewPass",
    },
  ];
  const checkForm = (values) => {
    const currentPassword = values?.oldpass || "";
    const newPassword = values?.newpass || "";
    const repeatNewPassword = values?.repeatNewPass || "";
    if (
      currentPassword === "" ||
      newPassword === "" ||
      repeatNewPassword === ""
    ) {
      return [false, "Empty form"];
    } else if (newPassword !== repeatNewPassword) {
      return [false, "Password not match"];
    } else if (newPassword.length < 8) {
      return [false, "Password Length Less than 8 characters"];
    }
    return [true, ""];
  };

  const checkResult = (result) => {
    if (result?.content === "ok") {
      history.push("/users");
      return [true, ""];
    } else {
      return [false, result?.content];
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Change password")}
          breadcrumbItem={props.t("Change password")}
        />
        <Row>
          <Col lg={12} style={{ textAlign: "right" }} className="mb-3">
            <Link to="/users" className="btn btn-success">
              Back
            </Link>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Formgenerator
                  fields={fields}
                  type="post"
                  action={"/users/changepass/" + userId}
                  beforeSubmit={checkForm}
                  afterSubmit={checkResult}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Changepassuser);
Changepassuser.propTypes = {
  t: PropTypes.any,
};
