import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get } from "../../helpers/api_helper";

const Itemdetails = (props) => {
  const [itemInfo, setItemInfo] = useState({});
  const itemId = props.match.params.id;
  useEffect(()=>{
    async function load(){
      const item = await get('/items/get/'+itemId);
      setItemInfo(item?.content[0]);
    }
    load();
  }, []);
  const [activeTab, setActiveTab] = useState("1");
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Item")}
          breadcrumbItem={props.t("Item Details")}
        />
        <Row>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={(e) => setActiveTab("1")}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={(e) => setActiveTab("2")}
                  >
                    Images
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row style={{ marginTop: "25px" }}>
                    <Col lg={6}>
                        <strong>Title</strong><br/>
                        <div>{itemInfo.title}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Category</strong>
                        <div>{itemInfo.category+ ' ' + itemInfo.subcategory }</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Item Id</strong>
                        <div>{itemInfo.id}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Owner</strong>
                        <div>{itemInfo.clientFirstName+ ' ' + itemInfo.clientLastName }</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Date Registered</strong>
                        <div>{itemInfo.date_registered}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Description</strong>
                        <div>{itemInfo.description}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Dimension ( h x w x l ) </strong>
                        <div>{itemInfo.height + ' x ' + itemInfo.width + ' x ' + itemInfo.length}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Medium</strong>
                        <div>{itemInfo.medium}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Total Autographs</strong>
                        <div>{itemInfo.numautographs}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Main Autograph</strong>
                        <div>{itemInfo.mainautograph}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>NFT Url</strong>
                        <div>{itemInfo.ntfurl}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Value</strong>
                        <div>{itemInfo.value}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Warehouse</strong>
                        <div>{itemInfo.warehouseTitle}</div>
                    </Col>
                    <Col lg={6}>
                        <strong>Where this item obtained ?</strong>
                        <div>{itemInfo.whereobtain}</div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2"></TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Itemdetails);
Itemdetails.propTypes = {
  t: PropTypes.any,
};
