import react, { useEffect } from "react";
import Switch from "react-switch";
import "./categorystyle.sass";
import { useState } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";

import React from "react";
const Signatures = (props) => {
  const [checkhed1, setChecked1] = useState(false);
  const [checkhed2, setChecked2] = useState(false);
  const [checkhed3, setChecked3] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index);
  };
  const handleChange1 = (nextChecked) => {
    setChecked1(nextChecked);
  };
  const handleChange2 = (nextChecked) => {
    setChecked2(nextChecked);
  };
  const handleChange3 = (nextChecked) => {
    setChecked3(nextChecked);
  };
  // const [formDetails, setformDetails] = useState({});
  // const handelSignaturesChange = (name, value) => {
  //   var newsignatures = formDetails.signatures;
  //   setformDetails((prev) => ({
  //     ...prev,
  //     signatures: {
  //       newsignatures,
  //       [name]: value,
  //     },
  //   }));
  // };
  return (
    <>
      <div className="accordion-item" onClick={() => onTitleClick(props.id)}>
        <h2 className="accordion-header" id="headingOne">
          <button
            className={
              props.id === 0 ? "accordion-button" : "accordion-button collapsed"
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {props.id + 1}) Artist
          </button>
        </h2>

        <div
          id="collapseOne"
          className={
            props.id === 0 || props.id === activeIndex
              ? "accordion-collapse collapse start-question-sig show"
              : "accordion-collapse collapse start-question-sig"
          }
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div>
            <div className="accordion-body">
              <Col lg={12}>
                <Select options={props.artits} id="artistsselect" />
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <h6 className="h6-two">1) Medium Ok?</h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Label>
                      <Switch
                        onChange={handleChange1}
                        checked={checkhed1}
                        uncheckedIcon={<div className="switch-no">No</div>}
                        checkedIcon={<div className="switch-yes">Yes</div>}
                        onColor="#52e32e"
                        offColor="#d3d3d3"
                        name="no1"
                      />
                    </Label>
                  </Col>
                </Row>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={6} className="column-style">
                    <h6 className="h6-two">2) Marker Type Ok?</h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Label>
                      <Switch
                        onChange={handleChange2}
                        checked={checkhed2}
                        uncheckedIcon={<div className="switch-no">No</div>}
                        checkedIcon={<div className="switch-yes">Yes</div>}
                        onColor="#52e32e"
                        offColor="#d3d3d3"
                      />
                    </Label>
                  </Col>
                </Row>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={6} className=" column-style">
                    <h6 className="h6-two">
                      3) Not Aouto Pen Or Not Pre-print?
                    </h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Label>
                      <Switch
                        onChange={handleChange3}
                        checked={checkhed3}
                        uncheckedIcon={<div className="switch-no">No</div>}
                        checkedIcon={<div className="switch-yes">Yes</div>}
                        onColor="#52e32e"
                        offColor="#d3d3d3"
                      />
                    </Label>
                  </Col>
                </Row>
              </Col>
              <Label className="width-label">
                <Col lg={12}>
                  <Row>
                    <Col lg={6} className=" column-style">
                      <h6 className="h6-two">4)Rate Flow Of autograph?</h6>
                    </Col>
                    <Col lg={6} className="response-ques">
                      <Input
                        type="range"
                        className="form-range"
                        min="0"
                        max="100"
                        width="100%"
                        step="1"
                        id="customRange3"
                        name="q-4"
                        value={props.signatures["q-4"]}
                        onChange={(e) =>
                          props.onChange("q-4", e.target.value, props.id)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Label>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <h6 className="h6-two">5) True To Comparables?</h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      width="100%"
                      step="0.5"
                      id="customRange3"
                      name="q-5"
                      value={props.signatures["q-5"]}
                      onChange={(e) =>
                        props.onChange("q-5", e.target.value, props.id)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <h6 className="h6-two">6) Rate autographs origin?</h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      width="100%"
                      step="0.5"
                      id="customRange3"
                      name="q-6"
                      value={props.signatures["q-6"]}
                      onChange={(e) =>
                        props.onChange("q-6", e.target.value, props.id)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <h6 className="h6-two">7) Provenance or Suported docs?</h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      width="100%"
                      step="0.5"
                      id="customRange3"
                      name="q-7"
                      value={props.signatures["q-7"]}
                      onChange={(e) =>
                        props.onChange("q-7", e.target.value, props.id)
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <h6 className="h6-two">
                      8) If Multiple,Pen Pressure Check?
                    </h6>
                  </Col>
                  <Col lg={6} className="response-ques">
                    <Input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      width="100%"
                      step="0.5"
                      id="customRange3"
                      name="q-8"
                      value={props.signatures["q-8"]}
                      onChange={(e) =>
                        props.onChange("q-8", e.target.value, props.id)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signatures;
