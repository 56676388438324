import PropTypes from "prop-types"
import React from "react"
import { useEffect,useState } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import jwt from 'jwt-decode'


//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

const Sidebar = props => {

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
        const token = localStorage.getItem("authUser");
        const obj = jwt(token);
        setUserInfo(obj);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img src="/profile.jpg" alt="" className="avatar-md mx-auto rounded-circle" />
            </div>

              <div className="mt-3">
                <Link to="#" className="text-dark fw-medium font-size-16">{userInfo.name}</Link>
                <p className="text-body mt-1 mb-0 font-size-13">{userInfo.title}</p>
              </div>
            </div>
            <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
          </div>
        </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {}
)(withRouter(withTranslation()(Sidebar)))