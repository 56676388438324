import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import DatatableTables from "../../common/Datatable/index"
import Dropdown from "../../common/Dropdown/index"
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
const items =[
  {
    label: "Order Details",
    link: "/",
  },
  {
    label: "Change Status",
    link: "/",
  },
]
const Orders = (props) => {
  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Client",
      field: "client",
      sort: "asc",
      width: 270,
    },
    {
      label: "Qnt",
      field: "quantity",
      sort: "asc",
      width: 200,
    },
    {
      label: "Est. Value",
      field: "estimate",
      sort: "asc",
      width: 100,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 150,
    },
    {
      label: "",
      field: "dd",
      sort: "disabled",
      width: 100,
    },
  ]
  const rowHandler = (data) => {
      const rows = [
        {
          id: data.orderId,
          client: data.firstname+" "+data.lastname,
          quantity: data.itemNum,
          date: new Date(data.dateRegistered).toDateString(),
          status: data.status,
          estimate: data.value,
          dd: <Dropdown items={items}/>
        }
      ]
      return rows
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={props.t("Orders")} breadcrumbItem={props.t("Orders")} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                  <div className="insertbtnDiv">
                    <Link to="/orders/new" className="btn btn-success insertbtn">New Order</Link>
                  </div>
                  <DatatableTables link={"/orders/list"} columns={columns} rows={rowHandler}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(Orders)

Orders.propTypes = {
  t: PropTypes.any
}
