import React from "react";
import { Row, Col, Card, CardBody} from "reactstrap";
import DatatableTables from "../../common/Datatable/index";
import Dropdown from "../../common/Dropdown/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
const Users = (props) => {

  const items = [
    {
      label: "Edit User",
      link: "/users/edit/:userId",
    },
    {
      label: "Change Password",
      link: "/users/changepassuser/:userId",
    },
    {
      label: "Delete User",
      url: '/users/delete/:userId',
      isRemove: true
    },
  ];

  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 270,
    },
    {
      label: "Title",
      field: "title",
      sort: "asc",
      width: 270,
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 200,
    },
    {
      label: "Date Registered",
      field: "dateRegistered",
      sort: "asc",
      width: 100,
    },
    {
      label: "",
      field: "dd",
      sort: "disabled",
      width: 100,
    },
  ];
  const rowHandler = (data) => {
    let newitem = [...items];
    newitem[0].link = newitem[0].link.replace(':userId', data.id);
    newitem[1].link = newitem[1].link.replace(':userId', data.id);
    newitem[2].url = newitem[2].url.replace(':userId', data.id);
    
    const rows = [
      {
        id: data.id,
        name: data.name,
        email: data.email,
        title: data.title,
        dateRegistered: data.date_registered,
        dd: <Dropdown items={newitem} />,
      },
    ];
    return rows;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Users")}
          breadcrumbItem={props.t("Users")}
        />

        <Row>
          <Col lg={12} style={{ textAlign: "right", marginBottom: "30px" }}>
            <Link to="/users/new" className="btn btn-success">
              New User
            </Link>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <DatatableTables
                  link="/users/list"
                  columns={columns}
                  rows={rowHandler}
                  backurl="/users"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Users);

Users.propTypes = {
  t: PropTypes.any,
};
