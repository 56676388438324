import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import DatatableTables from "../../common/Datatable/index";
import Dropdown from "../../common/Dropdown/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const items = [
  {
    label: "View ticket",
    link: "/tickets/showticket/:id",
  },
  {
    label: "Close ticket",
    link: "/",
  },
  {
    label: "Delete ticket",
    link: "/",
  },
];
const Tickets = (props) => {
  const defultShow = props.selfLoad ?? true;
  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Title",
      field: "title",
      sort: "asc",
      width: 270,
    },
    {
      label: "Department",
      field: "department",
      sort: "asc",
      width: 200,
    },
    {
      label: "Client",
      field: "client",
      sort: "asc",
      width: 100,
    },
    {
      label: "Type",
      field: "type",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
    {
      label: "",
      field: "dd",
      sort: "disabled",
      width: 100,
    },
  ];
  const rowHandler = (data) => {
    const rows = [
      {
        id: data.ticket_id,
        title: data.title,
        department: data.department_name,
        client: data.firstname + " " + data.lastname,
        status: data.status,
        type: data.type,
        dd: <Dropdown id={data.ticket_id} items={items} />,
      },
    ];
    return rows;
  };
  return (
    <React.Fragment>
      <div className={defultShow ? "page-content" : ""}>
        {defultShow ? (
          <Breadcrumbs
            title={props.t("Tickets")}
            breadcrumbItem={props.t("Tickets")}
          />
        ) : (
          ""
        )}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <DatatableTables
                  link={props.url ?? "/tickets/list"}
                  columns={columns}
                  rows={rowHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Tickets);
Tickets.propTypes = {
  t: PropTypes.any,
};
