import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Formgenerator from "../../components/Common/Formgenerator/formgenerator";
import { useHistory } from "react-router-dom";
import fields from "./fields";

const Newitem = (props) => {
  const history = useHistory();

  const checkResult = (result) => {
    if (result?.content === "ok"){
      history.push("/items");
      return [true,''];
    }
    else {
      return [false, result?.content];
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Items")}
          breadcrumbItem={props.t("New Items")}
        />
        <Row>
          <Col lg={12} style={{ textAlign: "right" }} className="mb-3">
            <Link to="/items" className="btn btn-success">
              Back
            </Link>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Formgenerator
                  fields={fields}
                  type="post"
                  action="/items/add"
                  afterSubmit={checkResult}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Newitem);
Newitem.propTypes = {
  t: PropTypes.any,
};
