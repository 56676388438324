import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { get } from '../../../helpers/api_helper';

const DbSelect  = (props) => {

    const [inputVal, setInputval] = useState(null);
    const [options, setOptions] = useState([]);

    const fetchData = async() =>{
        const optionsVal = await get(props.url+'/list/1');
        setOptions(optionsVal.content.map(item => props.map(item)));
    }

    useEffect(()=>{
        if(inputVal === null || inputVal.length > 3)
            fetchData();
    },[inputVal]);

    const loadOptions = (inputValue, callback) => {
        setInputval(inputValue ?? '');
        callback(options);
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue.value.replace(/\W/g, '');
        setInputval(inputValue);
        props.onChange(inputValue);
        return inputValue;
    };

    return ( <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={options}
        onChange={handleInputChange}
      />);
}

export default DbSelect;
