import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Category from "./category";
// import Dropzone from "react-dropzone";
const Authentication = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Authentication")}
          breadcrumbItem={props.t("Authentication")}
        />
        <Row>
          <Col className="col-12">
            <Card>
              <Category />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Authentication);
Authentication.propTypes = {
  t: PropTypes.any,
};
