import React, { useEffect } from "react"
import {useState} from 'react';
import { Row, Col, Card, CardBody, Input } from "reactstrap";
import { withTranslation } from "react-i18next"
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import Select from 'react-select';


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
const Orders = (props) => {
  const history = useHistory();
  const [formValues , setFormValues ] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(()=>{
      console.log(selectedItems);
  },[formValues]);
  const [dbItems, setDbItems] = useState([
    {
        id: '1002',
        title: 'Jayson Tatum Boston Celtics Game-Used Nike Jersey vs. Milwaukee Bucks on May 7, 2022 - Size 46+4',
        value: 2900.00,
        pic: 'https://vafloc02.s3.amazonaws.com/isyn/images/f414/img-3623414-f.jpg'
    },
    {
        id: '1003',
        title: 'Jayson Tatum & Larry Bird Boston Celtics Autographed Spalding Indoor/Outdoor Basketball',
        value: 2900.00,
        pic: 'https://vafloc02.s3.amazonaws.com/isyn/images/f378/img-3623378-f.jpg'
    },
    {
        id: '1004',
        title: 'Jayson Tatum Boston Celtics Game-Used Nike Jersey vs. Milwaukee Bucks on May 7, 2022 - Size 48+4',
        value: 2900.00,
        pic: 'https://vafloc02.s3.amazonaws.com/isyn/images/f414/img-3623414-f.jpg'
    },
    {
        id: '1005',
        title: 'Aaron Judge New York Yankees Autographed & Inscribed Game-Used Pants vs. Seattle Mariners on August 27, 2019',
        value: 310.00,
        pic: 'https://vafloc02.s3.amazonaws.com/isyn/images/f466/img-3619466-f.jpg'
    },
    {
        id: '1006',
        title: 'Stephen Curry Golden State Warriors Autographed Under Armour Left Shoe',
        value: 235.00,
        pic: 'https://vafloc02.s3.amazonaws.com/isyn/images/f441/img-3623441-f.jpg'
    }
    ]);

  const clientsUsernames = [
    { value: '1', label: 'Jules Roger ( lucas76@hotmail.fr )' },
    { value: '2', label: 'Augustin Morel ( ppoirier@lefort.com )' },
    { value: '3', label: 'Nathanaël Guerin ( collet.capucine@turpin.com )' },
    { value: '4', label: 'Zoé Blanchard ( alfred.charrier@bouygtel.fr )' },
    { value: '5', label: 'Joseph Guillaume ( lopes.theodore@renaud.fr )' },
  ];

  const Items = [
    { value: '1002', label: '1002 - Jaylen Brown Boston Celtics Game-Used Nike Jersey vs. Milwaukee Bucks on May 7, 2022 - Size 48+4' },
    { value: '1003', label: '1003 - Jayson Tatum & Larry Bird Boston Celtics Autographed Spalding Indoor/Outdoor Basketball' },
    { value: '1004', label: '1004 - Jayson Tatum Boston Celtics Game-Used Nike Jersey vs. Milwaukee Bucks on May 7, 2022 - Size 46+4' },
    { value: '1005', label: '1005 - Aaron Judge New York Yankees Autographed & Inscribed Game-Used Pants vs. Seattle Mariners on August 27, 2019' },
    { value: '1006', label: '1006 - Stephen Curry Golden State Warriors Autographed Under Armour Left Shoe' },
  ];

  const handleItemSelect = (selectedOption) => {
      const id = selectedOption.value;
      const dbItemSelect = dbItems.filter(item=>item.id === id)[0];
      if(selectedItems.filter(itm=>itm.id === id).length === 0) {
        setSelectedItems(oldValues=>{
            const newItems = [...oldValues];
            dbItemSelect.haveGrading = true;
            dbItemSelect.haveAuth    = true;
            newItems.push(dbItemSelect);
            return newItems;
        })
    }
  }

  const removeSelectedItemHandler = (index) => {
    setSelectedItems(oldItems=>{
        const newItems = [...oldItems];
        newItems.splice(index,1);
        return newItems;
    })
  }

  const handleSelectItem = (selectedOption, name) => {
    setFormValues(oldValues=>{
        const newItems = {...oldValues};
        newItems[name] = selectedOption;
        return newItems;
    })
  }

  const switchItmeHandler = (index, checked , name) => {
    setSelectedItems(oldItems=>{
        const newItems = [...oldItems];
        newItems[index][name] = checked;
        return newItems;
    })
  }

  const formSubmit = (e) => {
    history.push('/orders');
  }

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={props.t("Orders")} breadcrumbItem={props.t("New Order")} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                  <Row>
                      <Col lg={12}>
                          <label htmlFor="clientusername">Client</label>
                          <Select id="clientusername" value={formValues['clientUsername'] ?? null} onChange={item=>handleSelectItem(item,'clientUsername')}  options={clientsUsernames} />
                      </Col> 
                      <Col lg={12}>
                        <label htmlFor="itemsearch" className="mt-3">Item Search</label>
                        <Select id="itemsearch" value={null} onChange={handleItemSelect} options={Items} />
                      </Col>
                      <Col lg={12} className="mt-5">
                          {selectedItems.map((item,index)=>{
                            return (<Row className="mt-3" key={index}>
                              <Col lg={2}><img src={item.pic} width="90%" alt={item.title} /></Col>
                              <Col lg={4}>
                                  <h6>{item.title}</h6>
                                  <h6>${item.value}</h6>
                              </Col>
                              <Col lg={2}>
                                  <label>Authentication?</label>
                                  <div><Switch id={'fieldAuth'+index} className="react-switch"  onChange={chkd=>switchItmeHandler(index,chkd,'haveAuth')} checked={item.haveAuth ?? false} /></div>
                              </Col>
                              <Col lg={2}>
                                  <label>Grading?</label>
                                  <div><Switch id={'fieldGrading'+index} disabled className="react-switch" checked={false} /></div>
                              </Col>
                              <Col lg={2}>
                                  <button className="btn btn-danger" onClick={e=>removeSelectedItemHandler(index)}><i className="mdi mdi-trash-can-outline"></i></button>
                              </Col>
                            </Row>)
                          })}
                      </Col>
                      <Col lg={12} className="mt-5">
                          <button className="btn btn-success" onClick={formSubmit}>Submit New Order</button>
                      </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(Orders)

Orders.propTypes = {
  t: PropTypes.any
}
