import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : "https://api.hsauthentication.com";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(
  url,
  config = { "content-type": "application/x-www-form-urlencoded;charset=utf-8" }
) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response?.data);
}

export async function post(
  url,
  data,
  config = { "content-type": "application/x-www-form-urlencoded;charset=utf-8" }
) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function postFile(
  url,
  data,
  config = {}
) {
  return axiosApi
    .post(url, data , { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response?.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
