import { call, put, takeEvery } from "redux-saga/effects"

import {
  postJwtLogin
} from "../../../helpers/backend_helper"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"


function* loginUser({ payload: { user, history } }) {
  try {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response.content))
      yield put(loginSuccess(response))
      history.push("/dashboard")
  } catch (error) {
    console.log(error);
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    logoutUserSuccess();
    history.push("/login");
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
