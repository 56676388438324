const fields = [
    {
      label: "Title",
      type: "text",
      value: "",
      name: "title",
      required: true,
    },
    {
      label: "Category",
      type: "dropdown",
      colclass: 6,
      options: [
        { value: 1, label: 'Music' },
        { value: 2, label: 'Actor/Movies' },
        { value: 3, label: 'Historical' },
        { value: 4, label: 'Sports/Racing' },
        { value: 5, label: 'Art/Photography' },
        { value: 6, label: 'Pop/Culture' }
      ],
      name: "category",
      required: true,
    },
    {
      label: "Sub Category",
      type: "dropdown",
      colclass: 6,
      options: [
        { value: '1', label: 'Autographed Only' },
        { value: '2', label: 'Art' },
        { value: '3', label: 'Photograph' },
        { value: '4', label: 'Poster/Handbill' },
        { value: '5', label: 'Ticket' },
        { value: '6', label: 'Memorabilia' }
      ],
      name: "subcategory",
      required: true,
    },
    {
      label: "Client",
      type: "dbdropdown",
      colclass: 6,
      map: function(item){
        return {value: item.id, label: item.firstname+ " " + item.lastname + " ( "+ item.email +" )"};
      },
      url: '/clients',
      options: [
      ],
      name: "client",
      required: true,
    },
    {
      label: "Location",
      type: "dropdown",
      colclass: 6,
      options: [
        { value: '1', label: 'Client Location' },
        { value: '2', label: 'Canada Office' },
        { value: '3', label: 'UK Office' },
        { value: '4', label: 'USA Office' },
      ],
      name: "warehouses",
      required: true,
    },
    {
      label: "Medium",
      colclass: 3,
      type: "text",
      value: "",
      name: "medium",
      required: true,
    },
    {
      label: "How many autographs on it ?",
      colclass: 3,
      min: 1,
      max: 10,
      type: "number",
      name: "numautographs",
      required: true,
    },
    {
      label: "Main Autograph from?",
      colclass: 3,
      type: "text",
      name: "mainautograph",
      required: true,
    },
    {
      label: "NFT URL",
      colclass: 3,
      type: "text",
      name: "nfturl",
    },
    {
      label: "Performer",
      type: "text",
      colclass: 3,
      name: "itemperformer",
    },
    {
      label: "Venue",
      type: "text",
      colclass: 3,
      name: "itemvenue",
    },
    {
      label: "Promotor",
      type: "text",
      colclass: 2,
      name: "itempromotor",
    },
    {
      label: "Date Type",
      type: "dropdown",
      options: [
        { value: 1, label: 'Full Date' },
        { value: 2, label: 'Month / Year' },
        { value: 3, label: 'Year Only' }
      ],
      colclass: 2,
      name: "itemdateyeartype",
      value: 1
    },
    {
      label: "Date year of the item",
      type: "date",
      colclass: 2,
      name: "itemdateyear",
      attributes: (values)=> {
        if(values?.itemdateyeartype?.value === 3)
          return {
            dateFormat: "yyyy",
            showYearPicker: true,
          };
        if(values?.itemdateyeartype?.value === 2)
          return {
            dateFormat: "MM/yyyy",
            showMonthYearPicker: true,
          };
        else 
          return {
            dateFormat: "dd/MM/yyyy",
          };
      },
    },
    {
      label: "Description",
      colclass: 6,
      type: "textarea",
      value: "",
      name: "itemdescription",
    },
    {
      label: "How did client obtain this item?",
      colclass: 6,
      type: "textarea",
      value: "",
      name: "itemobtain",
    },
    {
      label: "Item Height ( cm )",
      colclass: 3,
      type: "number",
      value: 0,
      min: 0,
      max: 100000,
      name: "itemheight",
    },
    {
      label: "Item Width ( cm )",
      colclass: 3,
      type: "number",
      value: 0,
      name: "itemwidth",
    },
    
    {
      label: "Item Depth ( cm )",
      colclass: 3,
      type: "number",
      value: 0,
      min: 0,
      max: 10000,
      name: "itemlength",
    },
    {
      label: "Weight ( g )",
      colclass: 3,
      type: "number",
      value: 0,
      min: 0,
      max: 10000,
      name: "itemweight",
    },
    {
      label: "Edition Type",
      colclass: 4,
      type: "dropdown",
      options: [
        { value: '1', label: 'Open' },
        { value: '2', label: 'Limited' },
      ],
      value: '1',
      name: "itemedition",
    },
    {
      label: "Edition Number",
      colclass: 4,
      checkIsDisabled: (values) => values?.itemedition?.value !== '2',
      type: "number",
      min: 1,
      value: 1,
      name: "itemeditionnum",
    },
    {
      label: "Edition Total",
      colclass: 4,
      type: "number",
      min: 1,
      checkIsDisabled: (values) => values?.itemedition?.value !== '2',
      max: 10000,
      value: 1,
      name: "itemeditiontotal",
    },
    {
      label: "Upload at least 3 pictures showcasing the whole item",
      type: "dropzone",
      colclass: 4,
      name: "itemimages",
      required: true,
    },
    {
      label: "Upload at least one close up picture of each signature",
      type: "dropzone",
      colclass: 4,
      name: "signaturesimages",
    },
    {
      label: "Provenance / Paperwork / Prior letters of authenticity",
      type: "dropzone",
      colclass: 4,
      name: "documentimages",
    },
  ];

export default fields;