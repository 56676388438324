import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Formgenerator from "../../components/Common/Formgenerator/formgenerator";
import { Link } from "react-router-dom";
import { get } from "../../helpers/api_helper";
import { useHistory } from "react-router-dom";

const Edituser = (props) => {
  const history = useHistory();
  const [userInformation, setUserInformation] = useState({});

  const getUserInfo = useCallback(async(userId) => {
    const userInfo = await get('/users/get/' + userId);
    return userInfo?.content[0] ?? {};
  },[]);
  const userId = props.match.params.userId ?? -1;

  useEffect(() => {
      (async function() {
        const userInfo = await getUserInfo(userId);
        setUserInformation(userInfo);
      })();
  }, []);

  useEffect(()=>{
    console.log(userInformation);
  },[userInformation]);

  const fields = [
    {
      label: "Name",
      type: "text",
      value: userInformation.name,
      name: "name",
    },
    {
      label: "Title",
      type: "text",
      value: userInformation.title,
      name: "title",
    },
    {
      label: "Email",
      type: "text",
      value: userInformation.email,
      readOnly: true,
      name: "email",
    },
    {
      label: "Active",
      type: "switch",
      value: userInformation.status === 2 ? true : false,
      name: "switch",
    },
  ];

  const checkForm = (values) => {
    const name = values?.name || "";
    const title = values?.title || "";
    if (name === "" || title === "") {
      return [false, "Empty form"];
    }
    return [true, ""];
  };

  const checkResult = (result) => {
    if (result?.content === "ok"){
      history.push("/users");
      return [true, ''];
    }
    else {
      return [false, result?.content];
    }
  };  

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Users")}
          breadcrumbItem={props.t("Edit users")}
        />
        <Row>
          <Col lg={12} style={{ textAlign: "right" }} className="mb-3">
            <Link to="/users" className="btn btn-success">
              Back
            </Link>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Formgenerator
                  fields={fields}
                  values={userInformation}
                  type="post"
                  action={ "/users/edit/"+ userId }
                  beforeSubmit={checkForm}
                  afterSubmit={checkResult}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Edituser);
Edituser.propTypes = {
  t: PropTypes.any,
};
