import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import DatatableTables from "../../common/Datatable/index";
import Dropdown from "../../common/Dropdown/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const items = [
  {
    label: "Show Transaction",
    link: "/",
  },
  {
    label: "Close Transaction",
    link: "/",
  },
];
const Transaction = (props) => {
  const defultShowComponent = props.selfLoad ?? true;

  const columns = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Trans No",
      field: "transactionNo",
      sort: "asc",
      width: 270,
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: 200,
    },
    {
      label: "Payment Date",
      field: "paydate",
      sort: "asc",
      width: 100,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
    {
      label: "Client",
      field: "client",
      sort: "asc",
      width: 150,
    },
    {
      label: "",
      field: "dd",
      width: 100,
    },
  ];
  const rowHandler = (data) => {
    const rows = [
      {
        id: data.transactions_id,
        transactionNo: data.trans_number,
        amount: data.amount,
        paydate: data.pay_date,
        status: data.status,
        client: data.firstname + " " + data.lastname,
        dd: <Dropdown items={items} />,
      },
    ];
    return rows;
  };
  return (
    <React.Fragment>
      <div className={defultShowComponent ? "page-content" : ""}>
        {defultShowComponent ? (
          <Breadcrumbs
            title={props.t("Transactions")}
            breadcrumbItem={props.t("Transactions")}
          />
        ) : (
          ""
        )}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <DatatableTables
                  link={props.url ?? "/transactions/list"}
                  columns={columns}
                  rows={rowHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Transaction);

Transaction.propTypes = {
  t: PropTypes.any,
};
