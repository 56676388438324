import React from "react";
import { Row, Col, Card, CardBody, Badge } from "reactstrap";
import DatatableTables from "../../common/Datatable/index";
import Dropdown from "../../common/Dropdown/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { Link } from "react-router-dom";
const items = [
  {
    label: "View client",
    link: "/clients/view/:id",
  },
  {
    label: "Edit Client",
    link: "/clients/edit/:id",
  },
  {
    label: "Disable Client",
    link: "/",
  },
];
const columns = [
  {
    label: "Id",
    field: "id",
    sort: "asc",
    width: 150,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 270,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 200,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 100,
  },
  {
    label: "Register Date",
    field: "date",
    sort: "asc",
    width: 150,
  },
  {
    label: "",
    field: "null",
    sort: "disabled",
    width: 100,
  },
];
const rowHandler = (data) => {
  let newitem = JSON.parse(JSON.stringify(items));
  newitem[0].link = newitem[0].link.replace(':id', data.id);
  newitem[1].link = newitem[1].link.replace(':id', data.id);
  const rows = [
    {
      id: data.id,
      name: data.firstname + " " + data.lastname,
      email: data.email,
      date: data.date_registered,
      status: (data.status === 2 ? <span className="badge bg-success font-size-13">Active</span> : <span className="badge bg-danger font-size-13">Inactive</span>),
      null: <Dropdown items={newitem} />,
    },
  ];
  return rows;
};
const Clients = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Clients")}
          breadcrumbItem={props.t("Clients")}
        />
        <Row>
        <Col lg={12} style={{ textAlign: "right", marginBottom: "30px" }}>
            <Link to="/clients/new" className="btn btn-success">
              New Client
            </Link>
        </Col>
          <Col className="col-12">
            <Card>
              <CardBody>
                <DatatableTables
                  link={"/clients/list"}
                  columns={columns}
                  rows={rowHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Clients);

Clients.propTypes = {
  t: PropTypes.any,
};
